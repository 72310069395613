import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IFRAME_ACTIONS } from './utils/IframeActions';
import ChatUI from './ChatUI';

const WebViewModule = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [chatVisible, setChatVisible] = useState(true);
    const [messages, setMessages] = useState([]);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [sessionId] = useState(uuidv4());
    const closeChat = (value) => {
        if (!value) {
            window.parent.postMessage({ message: IFRAME_ACTIONS.CLOSE_IFRAME }, "*");
        }
    };

    const recieveMessage = event => {
        const message = event.data.message;
        const { CHATBOX_OPENED, CHATBOX_CLOSED, SEND_CHATBOX_DATA } = IFRAME_ACTIONS;
        switch (message) {
            case CHATBOX_CLOSED:
                setChatVisible(false);
                return;
            case CHATBOX_OPENED:
                setChatVisible(true);
                return;
            case SEND_CHATBOX_DATA:
                console.log(event.data);
                return;
            default:
                return;
        }
    };
    useEffect(() => {
        window.addEventListener("message", recieveMessage, false);
        window.parent.postMessage({ message: IFRAME_ACTIONS.APP_MOUNTED }, "*");
        return () => {
            window.removeEventListener("message", recieveMessage);
        };
    }, []);
    return (
        <ChatUI
            chatVisible={chatVisible}
            setChatVisible={closeChat}
            setIsLoading={setIsLoading}
            hasInitialized={hasInitialized}
            setHasInitialized={setHasInitialized}
            messages={messages}
            setMessages={setMessages}
            sessionId={sessionId}
            isWebView={true}
        />
    );
};

export default WebViewModule;