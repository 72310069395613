import React, { useState, useEffect, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { motion } from 'framer-motion';
import {
  FiSend,
  FiMic,
  FiX,
} from 'react-icons/fi';

const ChatUI = (props) => {
  const { chatVisible, setChatVisible, hasInitialized, setHasInitialized, messages, setMessages, sessionId } = props;
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const messagesEndRef = useRef(null);
  const mediaRecorder = useRef(null);
  const audioStream = useRef(null);
  const speakingRef = useRef(null);
  const [isTranscribing, setIsTranscribing] = useState(false);

  const initializeChat = useCallback(async () => {
    if (hasInitialized) {
      setChatVisible(true);
      messagesEndRef.current?.scrollIntoView();
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch('/api/init-chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessages([{ type: 'bot', content: data.message }]);
        setChatVisible(true);
        // speak(data.message);
        setHasInitialized(true);
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
    } finally {
      setIsLoading(false);
    }
  }, [hasInitialized]);


  const speak = async (text) => {
    if (speakingRef.current) {
      speakingRef.current.pause();
      speakingRef.current.currentTime = 0;
    }
    try {
      const response = await fetch('/api/synthesize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text }),
      });

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      speakingRef.current = new Audio(audioUrl);

      speakingRef.current.onended = () => {
        URL.revokeObjectURL(audioUrl);
      };

      speakingRef.current.play();
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;

    setMessages((prev) => [...prev, { type: 'user', content: messageText }]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: messageText, sessionId }),
      });

      const data = await response.json();
      if (response.ok) {
        let newMessages = [{ type: 'bot', content: data.message }];
        if (data.products && data.products.length > 0) {
          newMessages.push({ type: 'bot', products: data.products });
        }
        setMessages((prev) => [...prev, ...newMessages]);
        // speak(data.message);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      const audioChunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        await processAudioInput(audioBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (isRecording && mediaRecorder.current) {
      mediaRecorder.current.stop();
      audioStream.current.getTracks().forEach((track) => track.stop());
      setIsRecording(false);
    }
  };

  const processAudioInput = async (audioBlob) => {
    setIsTranscribing(true);
    const formData = new FormData();
    formData.append('audio', audioBlob);

    try {
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      if (data.text) {
        sendMessage(data.text);
      }
    } catch (error) {
      console.error('Error processing audio:', error);
    } finally {
      setIsTranscribing(false);
    }
  };

  const chatVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, y: 20, scale: 0.95 },
  };

  const messageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isRecording && !isLoading) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const micButtonVariants = {
    on: { scale: 1.2, backgroundColor: '#e53e3e', color: '#fff' },
    off: { scale: 1, backgroundColor: '#cbd5e0', color: '#000' },
  };

  const DotAnimation = () => {
    return (
      <div className="flex justify-center items-center">
        <span className="text-black-500 animate-pulse mr-1">●</span>
        <span className="text-black-500 animate-pulse delay-150 mr-1">●</span>
        <span className="text-black-500 animate-pulse delay-300">●</span>
      </div>
    );
  };

  useEffect(() => {
    if (chatVisible) {
      initializeChat();
    }
  }, [])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const renderProductCards = (products) => {
    return (
      <div className="flex overflow-x-auto space-x-4 py-2 -ml-4" style={{ scrollSnapType: 'x mandatory' }}>
        {products.map(product => (
          <div key={product.id} className="bg-white rounded-lg shadow-md w-60 flex-none p-4" style={{ scrollSnapAlign: 'start' }}>
            <div className="flex flex-col h-full">
              <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
              <p className="text-gray-600 mb-2">{product.title}</p>
              {product.imageUrl && (
                <img src={product.imageUrl} alt={product.title} className="w-full h-40 object-cover rounded-md mb-2" />
              )}
              <div className="mt-auto flex justify-between items-center">
                <span className="font-bold text-gray-800">${product.price}</span>
                {product.productUrl &&
                  <a href={product.productUrl} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm">
                    View
                  </a>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  if (chatVisible) {
    return (
      <div
        className='absolute top-0 left-0 right-0 bottom-0'
      >
        <div className="absolute z-10 top-0 left-0 right-0 flex justify-between items-center text-white text-lg font-semibold bg-gradient-to-r from-blue-500 to-purple-600 py-4 rounded-t-[13px] px-6">
          <div className="mr-10">
            <span style={{ fontSize: '1.5em', display: 'block', lineHeight: '1.2', fontFamily: 'Mulish, sans-serif;' }}>Hi, I’m Your AI Stylist! 👋</span>
            <span style={{ fontSize: '0.7em', lineHeight: '1.2', fontFamily: 'Mulish, sans-serif;' }}>Your Personal Fashion Guide.</span>
          </div>
          <button onClick={() => setChatVisible(false)} className="absolute top-2 right-2 p-2 hover:opacity-80 transition-opacity focus:outline-none">
            <FiX className="w-6 h-6" />
          </button>
        </div>
        <div className="absolute z-0 top-0 pt-28 pb-28 bottom-0 flex-1 overflow-y-auto space-y-3 px-4 py-2" style={{ height: 'auto' }}>
          {messages.map((message, index) => (
            <div key={index}>
              {message.type === 'bot' && message.content && (
                <motion.div
                  className="flex justify-start"
                  variants={messageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.2 }}
                >
                  <div
                    className="p-3 rounded-xl max-w-[75%] shadow-md transition duration-200 bg-gray-100 text-gray-800 rounded-tl-none"
                  >
                    {message.content}
                  </div>
                </motion.div>
              )}
              {message.type === 'bot' && message.products && (
                <motion.div
                  variants={messageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.2 }}
                >
                  {renderProductCards(message.products)}
                </motion.div>
              )}

              {message.type === 'user' && (
                <motion.div
                  className="flex justify-end"
                  variants={messageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.2 }}
                >
                  <div
                    className="p-3 rounded-xl max-w-[75%] shadow-md transition duration-200  bg-blue-600 text-white rounded-tr-none"
                  >
                    {message.content}
                  </div>
                </motion.div>
              )}
            </div>
          ))}
          {isLoading && <DotAnimation></DotAnimation>}
          {isTranscribing && <DotAnimation></DotAnimation>}
          <div ref={messagesEndRef}></div>
        </div>
        <div className="absolute z-10 bottom-0 left-0 right-0 flex items-center space-x-3 mt-4 p-2 border-t border-gray-200 bg-white">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message"
            className="flex-1 p-3 rounded-full bg-gray-100 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-400"
            disabled={isRecording || isLoading}
            onKeyDown={handleKeyDown}
          />
          <motion.button
            onClick={isRecording ? stopRecording : startRecording}
            className={`p-3 rounded-full transition duration-200 focus:outline-none`}
            disabled={isLoading}
            initial="off"
            animate={isRecording ? "on" : "off"}
            variants={micButtonVariants}
            whileTap={{ scale: 0.95 }}
          >
            <FiMic className="w-5 h-5" />
          </motion.button>
          <button
            onClick={() => sendMessage(input)}
            className="p-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-200 focus:outline-none"
            disabled={isLoading || !input.trim()}
          >
            <FiSend className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  }
  return null;
};

export default ChatUI;