import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from 'framer-motion';
import {
    FiSend,
    FiMic,
    FiX,
    FiMessageSquare,
    FiSun,
    FiMoon,
} from 'react-icons/fi';

const Messenger = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);
    const messagesEndRef = useRef(null);
    const [sessionId] = useState(uuidv4());
    const mediaRecorder = useRef(null);
    const audioStream = useRef(null);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [keyboardVisible, setKeyboardVisible] = useState(false);
    const chatBoxRef = useRef(null);


    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleFocus = () => {
            setKeyboardVisible(true);
            if (chatBoxRef.current && isMobile) {
                const visualViewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
                const translateYValue = `${Math.min(25, 100 - (visualViewportHeight / window.innerHeight) * 100)}vh`;
                chatBoxRef.current.style.transform = `translateY(-${translateYValue})`;
            }
        };

        const handleBlur = () => {
            setKeyboardVisible(false);
            if (chatBoxRef.current && isMobile) {
                chatBoxRef.current.style.transform = 'translateY(0)';
            }
        };


        if (isMobile) {
            window.addEventListener('focusin', handleFocus);
            window.addEventListener('focusout', handleBlur);
        }

        return () => {
            if (isMobile) {
                window.removeEventListener('focusin', handleFocus);
                window.removeEventListener('focusout', handleBlur);
            }
        };
    }, [isMobile]);


    const initializeChat = async () => {
        if (hasInitialized) {
            setChatVisible(true);
            return;
        }
        setIsLoading(true);
        try {
            const response = await fetch('/api/init-chat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ sessionId }),
            });

            const data = await response.json();
            if (response.ok) {
                setMessages([{ type: 'bot', content: data.message }]);
                setChatVisible(true);
                // await speak(data.message);
                setHasInitialized(true);
            }
        } catch (error) {
            console.error('Error initializing chat:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const speak = async (text) => {
        try {
            const response = await fetch('/api/synthesize', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ text }),
            });

            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);

            audio.onended = () => {
                URL.revokeObjectURL(audioUrl);
            };

            await audio.play();
        } catch (error) {
            console.error('Error playing audio:', error);
        }
    };


    const sendMessage = async (messageText) => {
        if (!messageText.trim()) return;

        setMessages((prev) => [...prev, { type: 'user', content: messageText }]);
        setInput('');
        setIsLoading(true);

        try {
            const response = await fetch('/api/chat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ message: messageText, sessionId }),
            });

            const data = await response.json();
            if (response.ok) {
                let newMessages = [{ type: 'bot', content: data.message }];
                if (data.products && data.products.length > 0) {
                    newMessages.push({ type: 'bot', products: data.products });
                }
                setMessages((prev) => [...prev, ...newMessages]);
                // await speak(data.message);
            }
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioStream.current = stream;
            mediaRecorder.current = new MediaRecorder(stream);
            const audioChunks = [];

            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            mediaRecorder.current.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                await processAudioInput(audioBlob);
            };

            mediaRecorder.current.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };


    const stopRecording = () => {
        if (isRecording && mediaRecorder.current) {
            mediaRecorder.current.stop();
            audioStream.current.getTracks().forEach((track) => track.stop());
            setIsRecording(false);
        }
    };

    const processAudioInput = async (audioBlob) => {
        setIsTranscribing(true);
        const formData = new FormData();
        formData.append('audio', audioBlob);

        try {
            const response = await fetch('/api/transcribe', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.text) {
                sendMessage(data.text);
            }
        } catch (error) {
            console.error('Error processing audio:', error);
        } finally {
            setIsTranscribing(false);
        }
    };

    const closeChat = () => {
        setChatVisible(false);
    };

    const toggleTheme = () => {
        setIsDarkMode(!isDarkMode);
    };

    const chatVariants = {
        hidden: { opacity: 0, y: 20, scale: 0.95 },
        visible: { opacity: 1, y: 0, scale: 1 },
        exit: { opacity: 0, y: 20, scale: 0.95 },
    };

    const messageVariants = {
        initial: { opacity: 0, y: 20 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -20 },
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !isRecording && !isLoading) {
            event.preventDefault();
            sendMessage(input);
        }
    };

    const DotAnimation = () => {
        return (
            <div className="flex justify-center items-center">
                <span className="text-black-500 animate-pulse mr-1">●</span>
                <span className="text-black-500 animate-pulse delay-150 mr-1">●</span>
                <span className="text-black-500 animate-pulse delay-300">●</span>
            </div>
        );
    };

    const renderProductCards = (products) => {
        return (
            <div className="flex overflow-x-auto space-x-4 py-2 -ml-4" style={{ scrollSnapType: 'x mandatory' }}>
                {products.map(product => (
                    <div key={product.id} className="bg-white rounded-lg shadow-md w-60 flex-none p-4" style={{ scrollSnapAlign: 'start' }}>
                        <div className="flex flex-col h-full">
                            <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                            <p className="text-gray-600 mb-2">{product.title}</p>
                            {product.imageUrl && (
                                <img src={product.imageUrl} alt={product.title} className="w-full h-40 object-cover rounded-md mb-2" />
                            )}
                            <div className="mt-auto flex justify-between items-center">
                                <span className="font-bold text-gray-800">₹{product.price}</span>
                                {product.productUrl &&
                                    <a href={product.productUrl} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm">
                                        View
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    return (
      <>
         <motion.button
                className="fixed bottom-5 right-5 p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition duration-300 focus:outline-none md:block hidden"
                onClick={initializeChat}
                disabled={isLoading}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: chatVisible ? 0 : 1, scale: chatVisible ? 0.8 : 1 }}
                transition={{ duration: 0.2 }}
                style={{ display: chatVisible ? 'none' : 'block' }}
            >
                <FiMessageSquare className="w-6 h-6" />
            </motion.button>
            <motion.button
                className="fixed bottom-5 right-5 p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition duration-300 focus:outline-none  md:hidden block"
                onClick={initializeChat}
                disabled={isLoading}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: chatVisible ? 0 : 1, scale: chatVisible ? 0.8 : 1 }}
                transition={{ duration: 0.2 }}
                style={{ display: chatVisible ? 'none' : 'block' }}
            >
                <FiMessageSquare className="w-6 h-6" />
            </motion.button>
        <div className={isDarkMode ? 'dark' : ''}>
            <AnimatePresence>
                {chatVisible && (
                    <motion.div
                        key="chat-window"
                        ref={chatBoxRef}
                         className={`fixed z-50 flex flex-col  ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} ${isMobile ? 'inset-0 rounded-t-none rounded-b-none rounded-r-none rounded-l-none' : 'max-w-sm bottom-5 right-5 left-auto w-96 rounded-3xl shadow-xl border border-gray-200'}`}
                        variants={chatVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                          transition={{ duration: 0.2 }}
                            style={{ maxHeight: isMobile ? '100vh':'70vh',  fontFamily: 'Assistant, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif' }}
                    >
                        <div className={`relative flex items-center text-white text-lg py-4 rounded-t-3xl px-6 justify-between ${isDarkMode ? 'bg-gradient-to-r from-gray-600 to-gray-900' : 'bg-gradient-to-r from-blue-400 to-rose-500'}`}>
                             <div className="w-[90%] mr-10">
                                <span style={{ fontSize: '1.3em', display: 'block', lineHeight: '1.2', fontFamily: 'Assistant, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif' }}>Hi, I’m Your AI Stylist! 👋</span>
                                <span style={{ fontSize: '0.7em', lineHeight: '1.2', fontFamily: 'Assistant, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif' }}>Your Personal Fashion Guide.</span>
                            </div>
                           <div className="flex items-center space-x-1 absolute top-2 right-1">
                                <button onClick={toggleTheme} className="p-2 focus:outline-none">
                                    {isDarkMode ? <FiSun className="w-5 h-5" /> : <FiMoon className="w-5 h-5" />}
                                </button>
                                 <button onClick={closeChat} className="p-2 hover:opacity-80 transition-opacity focus:outline-none">
                                    <FiX className="w-6 h-6" />
                                </button>
                            </div>
                        </div>
                         <div className="flex-1 overflow-y-auto mb-4 space-y-3 px-4 py-2" style={{ height: 'auto' }}>
                            {messages.map((message, index) => (
                                <div key={index}>
                                    {message.type === 'bot' && message.content && (
                                        <motion.div
                                            className="flex justify-start"
                                            variants={messageVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ duration: 0.2 }}
                                        >
                                            <div
                                                className={`p-3 rounded-xl max-w-[75%] shadow-md transition duration-200 ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'}  rounded-tl-none`}
                                            >
                                                {message.content}
                                            </div>
                                        </motion.div>
                                    )}
                                    {message.type === 'bot' && message.products && (
                                        <motion.div
                                            variants={messageVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ duration: 0.2 }}
                                        >
                                            {renderProductCards(message.products)}
                                        </motion.div>
                                    )}
                                    {message.type === 'user' && (
                                        <motion.div
                                            className="flex justify-end"
                                            variants={messageVariants}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            transition={{ duration: 0.2 }}
                                        >
                                            <div
                                                className="p-3 rounded-xl max-w-[75%] shadow-md transition duration-200 bg-blue-600 text-white rounded-tr-none"
                                            >
                                                {message.content}
                                            </div>
                                        </motion.div>
                                    )}
                                </div>
                            ))}
                            {isLoading && <DotAnimation></DotAnimation>}
                            {isTranscribing && <DotAnimation></DotAnimation>}
                            <div ref={messagesEndRef}></div>
                        </div>
                      <div className="flex items-center  mt-4 p-2 border-t border-gray-200 relative">
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="Type a message"
                                className={`flex-1 p-3 rounded-full focus:outline-none  pr-10 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white-100 text-gray-700'}`}
                                 disabled={isRecording || isLoading}
                                 onKeyDown={handleKeyDown}
                                  onFocus={()=> setKeyboardVisible(true)}
                                  onBlur={()=> setKeyboardVisible(false)}
                            />
                            {input.trim() ? (
                                <button
                                    onClick={() => sendMessage(input)}
                                    className="absolute right-2 top-2/4 -translate-y-2/4 p-3  transition duration-200 focus:outline-none"
                                     style={{ color: isDarkMode ? 'white' : '#4f46e5' }}
                                    disabled={isLoading || !input.trim()}
                                >
                                    <FiSend className="w-5 h-5 transform rotate-45" />
                                </button>
                            ) : (
                                <button
                                    onClick={isRecording ? stopRecording : startRecording}
                                    className={`absolute right-2 top-2/4 -translate-y-2/4 p-3 rounded-full  transition duration-200 focus:outline-none`}
                                   disabled={isLoading}
                                    style={{ backgroundColor: isRecording ? '#e53e3e' : 'transparent', color: isRecording ? 'white' : (isDarkMode ? 'white' : 'gray') }}
                                >
                                    <FiMic className="w-5 h-5" />
                                </button>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
       </>
    );
};

export default Messenger;