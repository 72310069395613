import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const VoiceFashionBot = () => {
  // State management
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId] = useState(uuidv4());
  const [systemData, setSystemData] = useState({});
  const [showDebug, setShowDebug] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [mode, setMode] = useState(null); // 'text' or 'voice'

  // Refs
  const messagesEndRef = useRef(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const audioContext = useRef(null);

  useEffect(() => {
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
  }, []);

  // Initialize chat when mode is selected
  useEffect(() => {
    if (mode) {
      initializeChat();
    }
  }, [mode]);

  const initializeChat = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/api/init-chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId, mode }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessages([{ type: 'bot', content: data.message }]);
        setSystemData(data.systemData);
        if (mode === 'voice') {
          await speakBotResponse(data.message);
        }
      }
    } catch (error) {
      console.error('Error initializing chat:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Voice Recording Functions
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
        await processAudioInput(audioBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  const processAudioInput = async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob);

    try {
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      if (data.text) {
        await sendMessage(data.text, true);
      }
    } catch (error) {
      console.error('Error processing audio:', error);
    }
  };

  const speakBotResponse = async (text) => {
    if (isSpeaking || mode !== 'voice') return;
    
    try {
      setIsSpeaking(true);
      const response = await fetch('/api/synthesize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text })
      });

      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const audio = new Audio(audioUrl);
      
      audio.onended = () => {
        setIsSpeaking(false);
        URL.revokeObjectURL(audioUrl);
      };

      await audio.play();
    } catch (error) {
      console.error('Error playing audio:', error);
      setIsSpeaking(false);
    }
  };

  // Chat Functions
  const sendMessage = async (messageText, isVoice = false) => {
    if (!messageText.trim()) return;

    setMessages(prev => [...prev, { type: 'user', content: messageText }]);
    setIsLoading(true);

    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          message: messageText,
          sessionId: sessionId
        }),
      });

      const data = await response.json();
      
      if (response.ok) {
        setMessages(prev => [...prev, { type: 'bot', content: data.message }]);
        setSystemData(data.systemData);
        
        if (mode === 'voice') {
          await speakBotResponse(data.message);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: 'Sorry, there was an error processing your request.'
      }]);
    } finally {
      setIsLoading(false);
      setInput('');
    }
  };

  // UI Components
  const VoiceControls = () => (
    <div className="voice-controls flex justify-center space-x-4 my-4">
      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`p-4 rounded-full ${
          isRecording 
            ? 'bg-red-500 text-white' 
            : 'bg-blue-500 text-white'
        } disabled:opacity-50`}
        disabled={isSpeaking}
      >
        {isRecording ? '⏹️ Stop' : '🎤 Start Recording'}
      </button>
    </div>
  );

  const DebugPanel = () => (
    showDebug && (
      <div className="mt-4 p-4 bg-gray-100 rounded-lg">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-bold text-lg">Debug Data:</h3>
          <button
            onClick={() => navigator.clipboard.writeText(JSON.stringify(systemData, null, 2))}
            className="px-3 py-1 bg-blue-500 text-white rounded-lg text-sm"
          >
            Copy JSON
          </button>
        </div>
        <pre className="bg-white p-4 rounded-lg shadow overflow-auto max-h-96">
          {JSON.stringify(systemData, null, 2)}
        </pre>
      </div>
    )
  );

  // Mode Selection Screen
  if (!mode) {
    return (
      <div className="max-w-2xl mx-auto p-4 flex flex-col items-center space-y-4">
        <h2 className="text-2xl font-bold">Choose Interaction Mode</h2>
        <div className="space-x-4">
          <button
            onClick={() => setMode('text')}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Text Chat
          </button>
          <button
            onClick={() => setMode('voice')}
            className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600"
          >
            Voice Chat
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg">
        {/* Messages Display */}
        <div className="h-96 overflow-y-auto p-4 space-y-4" ref={messagesEndRef}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-3 rounded-lg ${
                message.type === 'user'
                  ? 'bg-blue-100 ml-auto max-w-[80%]'
                  : message.type === 'error'
                  ? 'bg-red-100'
                  : 'bg-gray-100 mr-auto max-w-[80%]'
              }`}
            >
              {message.content}
            </div>
          ))}
          {isLoading && (
            <div className="flex items-center space-x-2">
              <div className="animate-bounce">●</div>
              <div className="animate-bounce delay-100">●</div>
              <div className="animate-bounce delay-200">●</div>
            </div>
          )}
        </div>

        {/* Voice Controls */}
        {mode === 'voice' && <VoiceControls />}
        
        {/* Text Input */}
        {mode === 'text' && (
          <form 
            onSubmit={(e) => { 
              e.preventDefault(); 
              sendMessage(input); 
            }} 
            className="border-t p-4"
          >
            <div className="flex space-x-4">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 p-2 border rounded-lg"
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || !input.trim()}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg disabled:bg-blue-300"
              >
                Send
              </button>
            </div>
          </form>
        )}
      </div>

      {/* Debug Toggle & Panel */}
      <button
        onClick={() => setShowDebug(!showDebug)}
        className="mt-2 px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300"
      >
        {showDebug ? 'Hide Debug Panel' : 'Show Debug Panel'}
      </button>
      <DebugPanel />
    </div>
  );
};

export default VoiceFashionBot;