import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import VoiceFashionBot from './VoiceFashionBot';
import Messenger from './messenger';
import DemoPage from './DemoPage';
import WebViewModule from './IframeContainer';

const Apps = () => {
  const [sessionId, setSessionId] = useState(uuidv4());

  return (
    <Router>
          <Routes>
            <Route path="/dopplr-stylist" element={<WebViewModule  />} />
            <Route path="/stylist-demo" element={<DemoPage />} />
            <Route path="/bot" element={<VoiceFashionBot />} />
            <Route path="/chat" element={<Messenger/>} />
          </Routes>
    </Router>
  );
};

export default Apps;
