import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { motion, AnimatePresence } from 'framer-motion';
import {
    FiSend,
    FiMic,
    FiX,
    FiMessageSquare,
} from 'react-icons/fi';
import ChatUI from './ChatUI';

const DemoPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [sessionId] = useState(uuidv4());
    const initializeChat = async () => {
        setChatVisible(true);
    };

    return (
        <div>
            {chatVisible && <div className="fixed bottom-0 md:bottom-5 md:right-5 w-full md:w-[400px] h-[70vh] bg-white rounded-3xl shadow-xl border border-gray-200 flex flex-col">
                <ChatUI
                    chatVisible={chatVisible}
                    setChatVisible={setChatVisible}
                    setIsLoading={setIsLoading}
                    hasInitialized={hasInitialized}
                    setHasInitialized={setHasInitialized}
                    messages={messages}
                    setMessages={setMessages}
                    sessionId={sessionId}
                /></div>}
            <motion.button
                className="fixed bottom-5 right-5 p-4 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition duration-300 focus:outline-none"
                onClick={initializeChat}
                disabled={isLoading}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: chatVisible ? 0 : 1, scale: chatVisible ? 0.8 : 1 }}
                transition={{ duration: 0.2 }}
                style={{ display: chatVisible ? 'none' : 'block' }}
            >
                <FiMessageSquare className="w-6 h-6" />
            </motion.button>
        </div >
    );
};

export default DemoPage;